import { Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from './guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/home/home.routes').then((r) => r.HOME_ROUTES)
      },
      {
        path: 'login',
        loadChildren: () => import('./pages/login/login.routes').then((r) => r.LOGIN_ROUTES)
      },
      {
        path: 'welcome',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/welcome/welcome.routes').then((r) => r.WELCOME_ROUTES)
      },

      {
        path: 'register',
        loadChildren: () => import('./pages/register/register.routes').then((r) => r.REGISTER_ROUTES)
      },
      {
        path: 'register-to-pay/:id',
        loadChildren: () => import('./pages/register-without-session/register-without-session.routes').then((r) => r.REGISTERWITHOUTHSESSION_ROUTES)
      },
      {
        path: 'bookings',
       // canActivate: [AuthGuard],
        loadChildren: () => import('./pages/bookings/bookings.routes').then((r) => r.BOOKINGS_ROUTES)
      },
      {
        path: 'payment-method/:id',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/payment-method/payment-method.routes').then((r) => r.PAYMENT_ROUTES)
      },
      {
        path: 'payment-validation',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/payment-method-by-email/payment-method-by-email.routes').then((r) => r.PAYMENT_BY_EMAIL_ROUTES)
      },
      {
        path: 'error',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/error-page/error-page.routes').then((r) => r.ERROR_ROUTES)
      },
      {
        path: 'calendar',
        // canActivate: [AuthGuard],
        loadChildren: () => import('./pages/calendar/calendar.routes').then((r) => r.CALENDAR_ROUTES)
      }
    ]
  }
];
