<div class="overlay" *ngIf="isLoading"></div>
<p-progressSpinner *ngIf="isLoading" ariaLabel="loading" class="loading-spinner"></p-progressSpinner>
<footer class="bg-image bg-cover bg-center w-full xl:px-32 2xl:px-52 pt-14 lg:pt-20">
  <!--Content and links-->
  <div
    class="w-full flex flex-wrap xl:flex-nowrap justify-content-center md:justify-content-between gap-8 sm:pt-0 md:pt-8">
    <!--Social Media -->
    <div class="w-full flex flex-column gap-5 align-items-center max-w-max">
      <img src="/assets/images/svg/logo-ribera-menu.svg" class="w-10rem" alt="Go to home" />
      <ul class="flex flex-row align-items-center gap-7 none-list">
        <li>
          <a href="https://www.facebook.com/RiberaDelRioClubResort/?locale=es_LA" target="_blank">
            <img src="/assets/images/svg/icon-facebook.svg" alt="Facebook" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/riberadelrioclubresort6" target="_blank">
            <img src="/assets/images/svg/icon-instagram.svg" alt="Instagram" />
          </a>
        </li>
        <li>
          <a href="https://www.tiktok.com/@clubriberadelrio" target="_blank">
            <img src="/assets/images/png/icon-tiktok.png" alt="Youtube" style="height: 38px;width: 38px;" />
          </a>
        </li>
      </ul>
    </div>
    <!--Riber and contact us | Mobile-->
    <div class="w-full flex justify-content-center gap-8 md:hidden">
      <!--Riber -->
      <div class="flex flex-column gap-4 w-full">
        <h5 class="m-0 text-white font-bold text-sm md:text-base">
          RIBERA DEL RÍO
        </h5>
        <div class="flex flex-column gap-1 link">
          <a (click)="onClick('/about')" class="m-0 text-white font-normal text-sm">Nosotros</a>
          <a (click)="onClick('/restaurant')" class="m-0 text-white font-normal text-sm">Restaurante</a>
          <a (click)="onClick('/experience')" class="m-0 text-white font-normal text-sm">Experiencias</a>
          <a (click)="onClick('/accommodation/rooms')" class="m-0 text-white font-normal text-sm">Habitaciones</a>
          <a (click)="onClick('/contact')" class="m-0 text-white font-normal text-sm">Contacto</a>
        </div>
      </div>
      <!--Contact Us-->
      <div class="flex flex-column gap-4 w-full">
        <h5 class="m-0 text-white font-bold text-base">CONTACTO</h5>
        <div class="flex flex-column gap-1">
          <span class="m-0 text-white font-bold text-sm">Teléfonos:</span>
          <span class="m-0 text-white font-normal text-sm">+51 938 627 411</span>
        </div>
        <div class="flex flex-column gap-1">
          <span class="m-0 text-white font-bold text-sm">Correo:</span>
          <span class="m-0 text-white font-normal text-sm break-all">
            informesyreservas&#64;cieneguillariberadelrio.com
          </span>
        </div>
      </div>
    </div>
    <!--Riber | Desktop -->
    <div class="hidden md:flex flex-column gap-4 w-full" style="max-width: 170px">
      <h5 class="m-0 text-white font-bold text-sm md:text-base">
        RIBERA DEL RÍO
      </h5>
      <div class="flex flex-column gap-1 link">
        <a (click)="onClick('/about')" class="m-0 text-white font-normal text-sm">Nosotros</a>
        <a (click)="onClick('/restaurant')" class="m-0 text-white font-normal text-sm">Restaurante</a>
        <a (click)="onClick('/experience')" class="m-0 text-white font-normal text-sm">Experiencias</a>
        <a (click)="onClick('/accommodation/rooms')" class="m-0 text-white font-normal text-sm">Habitaciones</a>
        <a (click)="onClick('/contact')" class="m-0 text-white font-normal text-sm">Contacto</a>
      </div>
    </div>
    <!--Contact Us | Desktop-->
    <div class="hidden md:flex flex-column gap-4 w-full" style="max-width: 200px">
      <h5 class="m-0 text-white font-bold text-base">CONTACTO</h5>
      <div class="flex flex-column gap-1">
        <span class="m-0 text-white font-bold text-sm">Teléfonos:</span>
        <span class="m-0 text-white font-normal text-sm">+51 938 627 411</span>
      </div>
      <div class="flex flex-column gap-1">
        <span class="m-0 text-white font-bold text-sm">Correo:</span>
        <span class="m-0 text-white font-normal text-sm break-all">
          informesyreservas&#64;cieneguillariberadelrio.com
        </span>
      </div>
    </div>
    <!--Ubicacion -->
    <div class="flex flex-column gap-4 w-full md:w-max md:max-w-300">
      <h5 class="m-0 text-white font-bold text-sm md:text-base">UBICACIÓN</h5>
      <div class="flex flex-column gap-1">
        <p class="m-0 text-white font-bold text-sm">Nos encontramos en:</p>
        <div class="flex gap-3">
          <img src="/assets/images/svg/icon-ubication.svg" alt="Ubicación" />
          <span class="m-0 text-white font-normal text-sm mt-1">
            Km 29.5 Carretera Lima - <br />
            Cieneguilla, Peru
          </span>
        </div>
      </div>
      <div
        class="flex flex-column gap-1 justify-content-center align-items-center md:justify-content-start md:align-items-start">
        <p class="m-0 text-white font-bold text-sm">Libro de reclamaciones</p>
        <a class="custom-claim-book" (click)="goToClaimBook()">
          <img src="/assets/images/svg/icon-claim-book.svg" alt="Claim book" class="w-24" />
        </a>
      </div>
    </div>
  </div>
  <div class="footer-container">
    <div class="border"></div>
    <div class="text-container">
      <span class="text-white text-sm">Términos y condiciones</span>
      <span class="text-white text-sm">Política de Uso de Datos</span>
      <span class="text-white text-sm">Política de Uso Aceptable</span>
    </div>
    <div class="border"></div>
  </div>


  <div class="whatsapp-float">
    <app-whatsapp-float></app-whatsapp-float>
  </div>


  <!-- <a
    href="https://api.whatsapp.com/send?phone=51938627411&text=Hola%2C%20quiero%20m%C3%A1s%20informaci%C3%B3n%20de%20los%20servicios%20del%20hotel%20Ribera%20del%20Rio."
    target="_blank"
    class="flex align-items-center justify-content-end gap-4 px-3 mt-3"
  >
    <img
      src="/assets/images/svg/whatsapp.svg"
      alt="WhatsApp icon"
      class="w-3rem pl-2 py-1"
    />
  </a> -->

  <span class="text-xs text-center font-normal w-full mt-4 pb-4 xl:mt-20 block" style="color: #d2cece">
    © 2024 Rivera del Río Todos los Derechos Reservados
  </span>
</footer>