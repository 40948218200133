<div class="overlay" *ngIf="isLoading"></div>
<p-progressSpinner
  *ngIf="isLoading"
  ariaLabel="loading"
  class="loading-spinner"
></p-progressSpinner>
<div class="layout-topbar">
  <div class="w-full">
    <p-menubar
      [model]="user || tokenStorage ? itemsSession : items"
      [ngClass]="{ 'session-menu': !!(user || tokenStorage) }"
    >
      <ng-template pTemplate="start">
        <a (click)="onLogoClick()" class="cursor-pointer">
          <img
            src="/assets/images/svg/logo-ribera-menu.svg"
            height="40"
            class="mr-2"
            alt="Logo"
          />
        </a>
      </ng-template>
      <ng-template let-item pTemplate="item">
        <a
          *ngIf="!item.separator"
          (click)="item.command ? item.command() : null"
          class="p-menuitem-link"
          [ngClass]="{ active: activeSection === item.label }"
        >
          <span
            class="p-menuitem-text"
            [ngClass]="{ active: activeSection === item.label }"
            >{{ item.label }}</span
          >
        </a>
      </ng-template>
      <ng-template
        *ngIf="user || tokenStorage ? itemsSession : items"
        pTemplate="end"
      >
        <div *ngIf="user" class="flex align-items-center gap-2">
          <div
            class="border-user flex align-items-center justify-content-center gap-2"
            (click)="toggleDropdown()"
          >
            <p-avatar
              image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png"
              shape="circle"
            ></p-avatar>
            <div class="flex gap-2">
              <span class="poppins-semibold user-fullname">{{
                dataUser?.firstName + " " + dataUser?.lastName
              }}</span>
              <i
                class="pi flex align-items-center"
                [ngClass]="isDropdownOpen ? 'pi-angle-up' : 'pi-angle-down'"
              ></i>
            </div>
            <!-- Dropdown menú de Cerrar sesión -->
            <div
              *ngIf="isDropdownOpen"
              class="absolute top-full bg-white shadow rounded"
            >
              <a class="item-logout cursor-pointer" (click)="logout()">
               Cerrar sesión
              </a>
            </div>
          </div>

          <ul class="flex flex-row align-items-center gap-2 none-list">
            <li>
              <a
                href="https://www.facebook.com/RiberaDelRioClubResort/?locale=es_LA"
                target="_blank"
              >
                <img
                  src="/assets/images/svg/icon-facebook.svg"
                  alt="Facebook"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/riberadelrioclubresort6"
                target="_blank"
              ></a>
              <img
                src="/assets/images/svg/icon-instagram.svg"
                alt="Instagram"
              />
            </li>
            <li>
              <img src="/assets/images/svg/no_pets.svg" alt="no pets" />
            </li>
          </ul>
        </div>
      </ng-template>
    </p-menubar>
  </div>
</div>
